import React, { useMemo, useState } from 'react'
import { TopNav, Box, Token, Icon, useMatchMedia } from '@revolut/ui-kit'
import { AppSwitcher } from '@revolut-internal/backoffice-ui-provider'
import { useSelector } from 'react-redux'

import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { SideBarEvent } from '@src/features/MainLayout/SideBar/SideBarEvent'
import Notifications from '@src/features/Notifications/Notifications'
import { GlobalSearch } from '@src/features/MainHeader/GlobalSearch'
import { ROUTES } from '@src/constants/routes'
import { UserProfile } from '@src/features/TopNav/UserProfile'
import { useBanner } from '@src/features/UIKitWithThemeProvider/BannerProvider'
import { useCoreNavigation } from '@src/features/MainLayout/useCoreNavigation'
import { selectAuthenticated, selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

const PositionOffsetWrapper: React.FC<{ noOffset?: boolean }> = ({
  noOffset,
  children,
}) => {
  const { visible } = useBanner()

  if (!visible || noOffset) {
    return <>{children}</>
  }

  return <Box style={{ position: 'relative', top: 28 }}>{children}</Box>
}

export const useTopNav = () => {
  const [enabled, setEnabled] = useState(false)

  const featureFlags = useSelector(selectFeatureFlags)

  const mdMedia = useMatchMedia(Token.media.md)
  const authenticated = useSelector(selectAuthenticated)
  const isCoreNavigation = useCoreNavigation()
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const topNav = useMemo(() => {
    if (enabled && isCoreNavigation && authenticated) {
      return {
        title: 'People',
        logo: (
          <PositionOffsetWrapper noOffset={mdMedia}>
            <TopNav.Logo
              useIcon={
                <Box style={{ position: 'relative' }}>
                  <Icon name="LogoRevolut" color={Token.color.white} />
                  <SideBarEvent />
                </Box>
              }
              to={ROUTES.MAIN}
              use={InternalLink}
              bg={Token.color.blue}
              color={Token.color.white}
            />
          </PositionOffsetWrapper>
        ),
        profile: (
          <PositionOffsetWrapper>
            <UserProfile />
          </PositionOffsetWrapper>
        ),
        appSwitcher: (
          <PositionOffsetWrapper>
            {isCommercial ? null : <AppSwitcher />}
          </PositionOffsetWrapper>
        ),
        globalSearch: (
          <PositionOffsetWrapper>
            <GlobalSearch />
          </PositionOffsetWrapper>
        ),
        notifications: (
          <PositionOffsetWrapper>
            <Notifications />
          </PositionOffsetWrapper>
        ),
      }
    }

    return undefined
  }, [enabled, isCoreNavigation, authenticated, mdMedia])

  return {
    topNav,
    setEnabled,
  }
}
