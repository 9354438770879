import React from 'react'
import { MoreButton } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useGetCandidateSettings } from '@src/api/settings'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

const ReferCandidateButton = () => {
  const { data: settings } = useGetCandidateSettings()
  const user = useSelector(selectUser)

  if (!settings?.enable_referral) {
    return null
  }

  return (
    <MoreButton.Action
      useIcon="Form"
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.REFERRALS, { id: user.id })}
    >
      Referrals
    </MoreButton.Action>
  )
}

export default ReferCandidateButton
